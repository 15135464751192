import {
  AspectRatio,
  Card,
  Col,
  Flex,
  Image,
  Link,
  Row,
  Text,
} from '@hausgold/designsystem';
import truncate from 'lodash/truncate';
import React from 'react';
import { getSrc } from 'gatsby-plugin-image';
import placeholderLogo from '../images/placeholder_page_logo.jpg';

const Content = ({ title = '', description = '', src, to }) => (
  <Link colorScheme="blue" href={to}>
    <Flex flexDirection="column" h="100%">
      <AspectRatio ratio={4 / 3}>
        <Image src={src} borderTopRadius="4px" />
      </AspectRatio>

      <Card p={4} flexGrow={1} overflow="word-break" borderTopRadius={0}>
        <Text size="lg" mb={2} color="blue.400" noOfLines={3}>
          {title}
        </Text>
        <Text color="gray.500" size="md" mb={0}>
          {description}
        </Text>
      </Card>
    </Flex>
  </Link>
);

/**
 * RelatedContent
 *
 * Component that renders 1-3 cards with relatedArticles and/or relatedPages as content.
 */

const RelatedContent = ({ content }) => {
  const isArticle = (item) => !!item?.category?.slug;
  const isPage = (item) => !!(item?.page && item?.page[0]?.slug);

  // Extract description from given page are article
  const getDescription = (item) => {
    if (isArticle(item)) {
      // Get either `relatedArticlesText` or nested `description` from article or return empty string as fallback
      return item?.relatedArticlesText || item?.description?.description || '';
    }

    // Get either `relatedPagesText` or `metaDescription` from page or return empty string as fallback
    return item?.relatedPagesText || item?.metaDescription || '';
  };

  const getHref = (item) => {
    if (isArticle(item)) {
      return `/${item.category.slug}/${item.slug}/`;
    }

    if (isPage(item)) {
      return `/${item.page[0].slug}/${item.slug}/`;
    }

    return `/${item.slug}/`;
  };

  const getImage = (item) => {
    if (getSrc(item.heroImage)) {
      return getSrc(item.heroImage);
    }

    if (getSrc(item.altHeroImage)) {
      return getSrc(item.altHeroImage);
    }

    return placeholderLogo;
  };

  const getTitle = (item) => item.title;

  // Length 103 instead of 100 because of "..." which comes from truncate lodash
  const truncateText = (text) => truncate(text, { length: '103' });

  return (
    <>
      <h2>Folgendes könnte Sie auch interessieren:</h2>
      <Row>
        {content.slice(0, 3).map((item) => (
          <Col pl={4} width={{ base: 1, md: 1 / 3 }} mb={6} key={item.id}>
            <Content
              to={getHref(item)}
              src={getImage(item)}
              title={getTitle(item)}
              description={truncateText(getDescription(item))}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default RelatedContent;
