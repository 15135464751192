// Derived from 'page.jsx'.

import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { Box, Divider, Heading, Stack } from '@hausgold/designsystem';
import cn from 'classnames';
import ContentBlock from '../components/ContentBlock';
import ContentfulFaqList from '../components/ContentfulFaqList';
import ExitIntent from '../components/ExitIntent';
import ExitIntentModal from '../components/ExitIntentModal';
import Formwizard from '../components/Formwizard';
import Header from '../components/Header';
import Layout from '../components/Layout';
import RelatedContent from '../components/RelatedContent';
import SEO from '../components/SEO';
import { collectFaqs } from '../utilities';
import AuthorBox from '../components/AuthorBox';
import Footer from '../components/partner-werden-prototype/Footer';

const PageTemplate = ({ data, location }) => {
  const page = get(data, 'contentfulPage');
  const site = get(data, 'site');
  const heroImage = get(page, 'heroImage');
  const formwizard = get(page, 'formwizard');
  const exitIntent = get(page, 'exitIntent');
  const relatedContent = get(page, 'relatedPages');
  const faqList = get(page, 'faqList');

  const formwizardPosition = get(formwizard, 'position');
  const formwizardUrl = get(formwizard, 'url');
  const contentBlocks = get(page, 'contentBlocks');
  const [showPopup, setShowPopup] = useState(false);

  const heading = page.title;
  const subHeading = page.subTitle;

  useEffect(() => {
    const removeExitIntent = ExitIntent({
      threshold: 10,
      eventThrottle: 100,
      onExitIntent: () => {
        setShowPopup(true);
      },
    });
    return () => {
      removeExitIntent();
    };
  });

  const faqItems = collectFaqs({
    faq: faqList,
    contentBlocks,
  });

  const breadcrumbs = [
    {
      title: 'Startseite',
      url: `${site.siteMetadata.siteUrl}/`,
    },
  ];

  const parentPath = 'fuer-makler';

  if (parentPath) {
    breadcrumbs.push({
      title: 'Für Makler',
      url: `${site.siteMetadata.siteUrl}/${parentPath}/`,
    });
  }

  breadcrumbs.push({
    title: page.title,
    url: `${site.siteMetadata.siteUrl}/${parentPath ? `${parentPath}/` : ''}${
      page.slug
    }/`,
  });

  return (
    <Layout
      location={location}
      cookieConsent
      header={<Header template={page.template} />}
      footer={<Footer styles={{ marginTop: '32px' }} />}
    >
      <Helmet>
        <meta name="content-entity" content="page" />
        <meta name="content-id" content={page.contentful_id} />
      </Helmet>

      <SEO
        entity={{ ...page, imageSrc: getSrc(heroImage) }}
        title={page.metaTitle || page.headline}
        canonical={page.canonical}
        type="page"
        description={page.metaDescription}
        indexable={page.indexable}
        breadcrumbs={breadcrumbs}
        faqItems={faqItems}
      />

      {heading && (
        <Container
          className={cn({
            'container-narrow': formwizardPosition !== 'right',
          })}
        >
          <Stack spacing={0}>
            <Heading
              as="h1"
              fontSize={{ base: '2rem', md: '2.5rem' }}
              px="0"
              style={{ hyphens: 'auto' }}
              fontWeight="bold"
            >
              {heading}
              <br />
              <Box
                fontWeight="semibold"
                as="span"
                fontSize={{ base: '1rem', md: '1.5rem' }}
              >
                {subHeading}
              </Box>
            </Heading>
          </Stack>
        </Container>
      )}

      <Container>
        <Row className="justify-content-between">
          <Col
            xs="12"
            lg={formwizardPosition === 'right' ? 7 : 12}
            className={`order-2 ${
              formwizardPosition === 'right' ? 'order-md-1' : 'order-md-2'
            }`}
          >
            <div
              className="body markdown"
              dangerouslySetInnerHTML={{
                __html: get(page, 'body.childMarkdownRemark.html'),
              }}
            />
          </Col>
          {(formwizardPosition === 'right' || formwizardPosition === 'top') && (
            <Col
              xs="12"
              lg={formwizardPosition === 'right' ? 5 : 12}
              xl={formwizardPosition === 'right' ? 4 : 12}
              className={`order-1 ${
                formwizardPosition === 'right' ? 'order-md-2' : 'order-md-1'
              }`}
            >
              {formwizardPosition && (
                <Formwizard
                  position={formwizardPosition}
                  location={location}
                  url={formwizardUrl}
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
      {page.author && (
        <Container
          className={cn({
            'container-narrow': formwizardPosition !== 'right',
          })} // Copied from below "ContentBlock" container class for equal width
        >
          <AuthorBox
            title={page.author?.title}
            name={`${page.author?.firstName} ${page.author?.lastName}`}
            imageUrl={getSrc(page.author?.image)}
            lastUpdate={page.updatedAt}
          />
          <Divider />
        </Container>
      )}
      {contentBlocks &&
        contentBlocks.map((block) => (
          <ContentBlock
            key={block.id}
            block={block}
            narrow={formwizardPosition !== 'right'}
            location={location}
            source="page"
          />
        ))}

      {page.author && (
        <Container
          className={cn({
            'container-narrow': formwizardPosition !== 'right',
          })} // Copied from below "ContentBlock" container class for equal width
        >
          <Stack my="4">
            <h2>
              {page.author?.isMale ? 'Über den Autor' : 'Über die Autorin'}
            </h2>
            <AuthorBox
              style={{
                p: '5',
                shadow: 'sm',
              }}
              title={page.author?.title}
              name={`${page.author?.firstName} ${page.author?.lastName}`}
              imageUrl={getSrc(page.author?.image)}
              bio={page.author?.bio?.childMarkdownRemark?.html}
              profileLink={`/experten/${page.author?.slug}`}
              socialMediaLinks={page.author?.socialMedia}
            />
          </Stack>
        </Container>
      )}

      {exitIntent && (
        <ExitIntentModal
          show={showPopup}
          image={exitIntent.image}
          text={exitIntent.text.childMarkdownRemark.html}
          button={exitIntent.button}
          setShowPopup={setShowPopup}
        />
      )}

      <Container className="container-narrow">
        {relatedContent && <RelatedContent content={relatedContent} />}
        {faqList && (
          <>
            <h2>Häufig gestellte Fragen:</h2>
            <ContentfulFaqList entry={faqList} />
          </>
        )}
      </Container>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      useArticleSchema
      contentful_id
      id
      title
      headline
      headlineColor
      subTitle
      indexable
      metaTitle
      metaDescription
      slug
      updatedAt
      createdAt
      template
      author {
        id
        isMale
        lastName
        firstName
        company
        email
        title
        slug
        tag
        image {
          gatsbyImageData(layout: FULL_WIDTH, width: 800)
          title
        }
        bio {
          childMarkdownRemark {
            html
          }
        }
        freeText {
          childMarkdownRemark {
            html
          }
        }
        socialMedia {
          facebook
          instagram
          website
          twitter
          linkedin
          xing
        }
      }
      page {
        id
        slug
        title
      }
      formwizard {
        position
        url
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      exitIntent {
        image {
          gatsbyImageData(layout: FIXED, width: 170)
          title
        }
        text {
          childMarkdownRemark {
            html
          }
        }
        button {
          title
          url
          color
          size
          shadow
        }
      }
      relatedPages {
        ... on ContentfulPage {
          id
          slug
          title
          metaDescription
          relatedPagesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          altHeroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              height: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          page {
            id
            slug
            title
          }
        }

        ... on ContentfulArticle {
          id
          slug
          title
          metaDescription
          relatedArticlesText
          heroImage {
            gatsbyImageData(
              layout: FIXED
              width: 600
              height: 600
              cropFocus: FACES
              resizingBehavior: FILL
            )
          }
          category {
            slug
          }
          description {
            description
            id
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
      faqList {
        faqItems {
          id
          answer {
            childMarkdownRemark {
              html
            }
          }
          question
        }
      }
      contentBlocks {
        title
        id
        tocPosition
        entries {
          ... on ContentfulContentBreaker {
            internal {
              type
            }
            id
            headline1
            description
            linkLabel
            link
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          ... on ContentfulDownloadItem {
            internal {
              type
            }
            id
            title
            file {
              file {
                url
              }
              title
            }
            link
          }
          ... on ContentfulDownloadList {
            internal {
              type
            }
            id
            title
            downloadItems {
              title
              file {
                file {
                  url
                }
              }
            }
          }
          ... on ContentfulList {
            internal {
              type
            }
            id
            listItems {
              internal {
                type
              }
              children {
                id
              }
              title
              id
              icon
              image {
                gatsbyImageData(layout: FIXED, width: 170)
                title
              }
              text {
                childMarkdownRemark {
                  html
                }
              }
            }
            title
            template
          }
          ... on ContentfulListItem {
            internal {
              type
            }
            children {
              id
            }
            title
            id
            icon
            image {
              gatsbyImageData(layout: FIXED, width: 170)
              title
            }
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulText {
            internal {
              type
            }
            id
            text {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulTextWithImage {
            internal {
              type
            }
            id
            title
            template
            imageAlignment
            body {
              childMarkdownRemark {
                html
              }
            }
            image {
              gatsbyImageData(layout: FULL_WIDTH, width: 800)
              title
            }
          }
          ... on ContentfulFaqList {
            internal {
              type
            }
            id
            faqItems {
              id
              answer {
                childMarkdownRemark {
                  html
                }
              }
              question
            }
          }
          ... on ContentfulButton {
            ...ContentfulButtonFragment
          }
          ... on ContentfulFormwizard {
            internal {
              type
            }
            id
            position
            title
            url
            variant
          }
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FIXED
          width: 1920
          height: 500
          cropFocus: FACES
          resizingBehavior: FILL
        )
        title
      }
    }
  }
`;
